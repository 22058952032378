import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import "../styles/gallery.css";

// Components

const GalleryContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
  max-height: 100%;

  padding: 0;

  z-index: 1;

  & .image-gallery {
    height: 100%;
    max-height: 100%;
  }

  & .carousel.carousel-slider,
  & .slider-wrapper,
  & .slider,
  & .slide,
  & video,
  & img {
    height: 100%;
    max-height: 100%;
  }

  & .carousel.carousel-slider .control-arrow {
    padding: 0;
    width: 50%;
  }

  // @media (max-width: 999px) {
  //   height: auto;
  //   max-height: unset;
  // }
`;

const ImageContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  overflow: hidden;

  & a {
    display: block;

    width: 100%;
    height: 100%;

    position: relative;
  }

  & img {
    object-fit: ${props => (props.objectFit ? props.objectFit : `cover`)};
  }
`;

const Caption = styled.div`
  font-family: "neue-haas-grotesk-text", sans-serif;
  font-weight: 700;
  font-style: normal;

  font-size: 23px;
  line-height: 20px;

  letter-spacing: 0.02em;
  text-transform: uppercase;

  position: absolute;
  bottom: 20px;
  left: 20px;

  // @media (max-width: 999px) {
  //   position: relative;
  //   width: 100%;

  //   margin: 10px 0 0 0;

  //   font-size: 12px;
  //   line-height: 10px;

  //   bottom: 0;
  //   left: 0;

  //   display: flex;
  //   flex-direction: row;
  //   flex-wrap: wrap;
  //   justify-content: space-between;

  //   & .counter {
  //     width: 40px;
  //   }

  //   & .caption-text {
  //     margin: 0 auto;
  //   }
  // }
`;

export const Gallery = ({ data, objectFit, selectedIndex }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [totalSlides, setTotalSlides] = useState(null);

  useEffect(() => {
    const initialSlideIndex = data.findIndex(x => x.id === selectedIndex);
    setCurrentSlide(initialSlideIndex);
  }, [selectedIndex]);

  useEffect(() => {
    setTotalSlides(data.length);
  }, []);

  const updateCurrentSlide = index => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  const galleryContent = data.map((content, index) => (
    <ImageContainer key={`project_images_${index}`} objectFit={objectFit}>
      <img
        src={content.items[0].images.fluid.srcWebp}
        srcSet={content.items[0].images.fluid.srcSetWebp}
        alt={content.items[0].images.alt}
        loading="lazy"
      />
    </ImageContainer>
  ));

  return (
    <GalleryContainer>
      {galleryContent.length > 1 ? (
        <>
          <Carousel
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            infiniteLoop={true}
            useKeyboardArrows={true}
            className="image-gallery"
            selectedItem={currentSlide}
            onChange={index => updateCurrentSlide(index)}
            autoPlay={false}
            stopOnHover={false}
            interval={8000}
            transitionTime={0}
          >
            {galleryContent}
          </Carousel>
        </>
      ) : (
        <Carousel
          showThumbs={false}
          showIndicators={false}
          showStatus={false}
          infiniteLoop={false}
          useKeyboardArrows={false}
          className="image-gallery"
          selectedItem={currentSlide}
          onChange={index => updateCurrentSlide(index)}
          autoPlay={false}
          stopOnHover={false}
          interval={0}
        >
          {galleryContent}
        </Carousel>
      )}

      <Caption>
        {currentSlide + 1}/{totalSlides}
      </Caption>
    </GalleryContainer>
  );
};
