import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

// Components
import { Lightbox } from "../images/lightbox";
import { VideoWithControls } from "../video/video-with-controls";

const Title = styled.div`
  & h1 {
    text-align: center;
    text-transform: none;
  }

  margin: 7px 0 47px 0;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${props => props.desktopCol};

  // margin-bottom: 60px;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.justifyContent};
`;

const FlexCol = styled.div`
  flex: ${props => props.flex};
  max-width: ${props => props.flex};

  margin: 0 0 60px 0;

  & .image-text {
    max-width: 450px;

    & p:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const GridCol = styled.div`
  width: 100%;
  padding: 20px;

  &.scrollable {
    overflow: scroll;
    height: 100vh;
  }

  &:first-of-type {
    border-right: 1px solid #555;
  }

  & .projects-text {
    & .nhg-small {
      margin: 0 0 6px 0;
      line-height: 15px;
    }
  }

  & .projects-text,
  & .related-projects {
    margin: 12px 0 10px 0;

    & .single-line-project-text {
      margin: 0 0 16px 0;

      &:last-of-type {
        margin: 0;
      }

      & h2,
      & p {
        line-height: 125%;
      }

      & p {
        margin: 0;
      }
    }
  }

  & .related-projects {
    & .nhg-small {
      margin: 0 0 6px 0;
      line-height: 15px;
    }

    & h1 {
      font-family: "RotationLTStd-Roman";
      font-size: 15px;
      line-height: 20px;

      &:hover {
        font-family: "RotationLTStd-Italic";
      }
    }
  }

  & .project-text {
    margin: 0 0 9px 0;

    & p:first-of-type {
      margin-top: 0;
    }
    & p:last-of-type {
      margin-bottom: 0;
    }

    & strong {
      font-family: unset;
      font-size: unset;
      line-height: unset;
      letter-spacing: unset;
      text-transform: unset;
      font-weight: unset;
    }
  }
`;

const ImageContainer = styled.div`
  & img {
    cursor: ${props => props.cursor};
  }
`;

const ExpandableCreditsContainer = styled.div`
  margin: 11px 0 10px 0;

  & button {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    width: 100%;
    cursor: pointer;

    margin: 0 !important;
    line-height: 1 !important;
  }

  & .expandable-credits {
    display: ${props => props.display};
    margin: 1em 0;
  }
`;

export const ProjectDesktop = ({ data, setPageColor, setPageType }) => {
  const [lightboxItems, setLightboxItems] = useState(null);
  const [isLightboxOpen, setLightBoxOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isExpandableCreditsOpen, setIsExpandableCreditsOpen] = useState(false);

  useEffect(() => {
    setPageColor(data.prismicProject.data.color);
    setPageType("single-project");

    document.body.style.overflow = "hidden";
  }, [data]);

  const lightboxImages = data.prismicProject.data.body
    .filter(content => content.slice_type === "image_row")
    .filter(content => content.show_in_lightbox !== false)
    .map(content => {
      const items = content.items
        .filter(image => image.images !== undefined)
        .filter(
          image =>
            image.images.fluid !== null && image.images.fluid !== undefined
        );

      return (content = {
        items: items,
        id: content.id,
      });
    })
    .filter(content => content.items.length >= 1);

  useEffect(() => {
    if (lightboxImages.length >= 1) {
      setLightboxItems(lightboxImages);
      setSelectedIndex(lightboxImages[0].id);
    }
  }, []);

  const content = data.prismicProject.data.body.map((content, outerIndex) => {
    let contentWidth;
    let contentAlignment;
    if (content.primary.content_width === "Small") {
      contentWidth = `33.333%`;
    }
    if (content.primary.content_width === "Medium") {
      contentWidth = `50%`;
    }
    if (content.primary.content_width === "Large") {
      contentWidth = `100%`;
    }

    if (content.primary.content_alignment === "Left") {
      contentAlignment = `flex-start`;
    }
    if (content.primary.content_alignment === "Center") {
      contentAlignment = `center`;
    }
    if (content.primary.content_alignment === "Right") {
      contentAlignment = `flex-end`;
    }

    if (content.slice_type === "image_row") {
      const filteredImages = content.items
        .filter(image => image.images !== undefined)
        .filter(
          image =>
            image.images.fluid !== null && image.images.fluid !== undefined
        );

      const firstImage = filteredImages
        .filter((image, index) => index === 0)
        .map((image, index) => (
          <ImageContainer
            key={`first_image_${index}_${content.id}`}
            onClick={() => {
              if (content.show_in_lightbox !== false) {
                setLightBoxOpen(!isLightboxOpen);
                setSelectedIndex(content.id);
              } else {
                return null;
              }
            }}
            cursor={`pointer`}
          >
            <img
              srcSet={image.images.fluid.srcSetWebp}
              src={image.images.fluid.srcWebp}
              alt={image.images.alt}
              loading="lazy"
            />

            {/* {filteredImages.length > 1 && (
              <Caption className="nhg-small">1/{filteredImages.length}</Caption>
            )} */}
          </ImageContainer>
        ));

      return (
        <Flex
          key={`project_content_row_${outerIndex}_${data.prismicProject.id}`}
          justifyContent={contentAlignment}
        >
          <FlexCol flex={contentWidth}>
            {firstImage.length >= 1 && firstImage}
            <div
              className="image-text"
              dangerouslySetInnerHTML={{
                __html: content.primary.image_text.html,
              }}
            />
          </FlexCol>
        </Flex>
      );
    }

    if (content.slice_type === "content_row_-_video") {
      return (
        <Flex
          key={`project_content_row_${outerIndex}_${data.prismicProject.id}`}
          justifyContent={contentAlignment}
        >
          <FlexCol flex={contentWidth}>
            <VideoWithControls
              id={`project_content_row_${outerIndex}_${data.prismicProject.id}`}
              video={content.primary.video.html}
            />
            <div
              className="image-text"
              dangerouslySetInnerHTML={{
                __html: content.primary.video_text.html,
              }}
            />
          </FlexCol>
        </Flex>
      );
    }
  });

  const additionalCredits = data.prismicProject.data.additional_credits.map(
    (content, index) => (
      <div
        key={`additional_credits_${index}`}
        className="single-line-project-text"
      >
        <div
          className="nhg-small"
          dangerouslySetInnerHTML={{
            __html: content.credit_title.html,
          }}
        />
        <div
          className="body-text"
          dangerouslySetInnerHTML={{
            __html: content.credit_text.html,
          }}
        />
      </div>
    )
  );

  const expandableCredits = data.prismicProject.data.expandable_credits
    .filter(content => content.credit_title.html !== "")
    .map((content, index) => (
      <div
        key={`expandable_credits_${index}`}
        className="single-line-project-text"
      >
        <div
          className="nhg-small"
          dangerouslySetInnerHTML={{
            __html: content.credit_title.html,
          }}
        />
        <div
          className="body-text"
          dangerouslySetInnerHTML={{
            __html: content.credit_text.html,
          }}
        />
      </div>
    ));

  const relatedProjects = data.prismicProject.data.related_projects
    .filter(content => content.project.document !== null)
    .map((content, index) => (
      <div key={`related_projects_${index}`}>
        <Link to={`/projects/${content.project.document.uid}`}>
          <div
            dangerouslySetInnerHTML={{
              __html: content.project.document.data.title.html,
            }}
          />
        </Link>
      </div>
    ));

  return (
    <>
      <Grid desktopCol={`440px 1fr`}>
        <GridCol className="scrollable">
          <div
            className="project-text"
            dangerouslySetInnerHTML={{
              __html: data.prismicProject.data.text.html,
            }}
          />

          <hr />

          <div className="projects-text">
            <div className="single-line-project-text">
              <h2 className="nhg-small">Client</h2>
              <div
                className="body-text"
                dangerouslySetInnerHTML={{
                  __html: data.prismicProject.data.client.html,
                }}
              />
            </div>

            {additionalCredits}

            {expandableCredits.length >= 1 && (
              <>
                <hr />

                <ExpandableCreditsContainer
                  display={isExpandableCreditsOpen === true ? `block` : `none`}
                >
                  <button
                    className="nhg-small"
                    onClick={() =>
                      setIsExpandableCreditsOpen(!isExpandableCreditsOpen)
                    }
                  >
                    {data.prismicProject.data.expandable_credits_title.text}

                    <span>{isExpandableCreditsOpen ? `–` : `+`}</span>
                  </button>

                  <div className="expandable-credits">{expandableCredits}</div>
                </ExpandableCreditsContainer>
              </>
            )}
          </div>

          {relatedProjects.length >= 1 && (
            <>
              <hr />
              <div className="related-projects">
                <div className="nhg-small">
                  <h2>Related</h2>
                </div>
                {relatedProjects}
              </div>
            </>
          )}
        </GridCol>

        <GridCol className="scrollable">
          <Title
            className="nhg-title"
            dangerouslySetInnerHTML={{
              __html: data.prismicProject.data.title.html,
            }}
          />

          <div>{content}</div>
        </GridCol>
      </Grid>

      {lightboxItems !== null && (
        <Lightbox
          data={lightboxItems}
          isLightboxOpen={isLightboxOpen}
          setLightBoxOpen={setLightBoxOpen}
          selectedIndex={selectedIndex}
        />
      )}
    </>
  );
};
