import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Div100vh from "react-div-100vh";
import { Gallery } from "./gallery";

const LightboxContainer = styled.div`
  display: ${props => (props.displayLightbox === true ? `block` : `none`)};

  & > div {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 300;

    background-color: rgba(255, 255, 255, 0.95);

    padding: 0;

    overflow: hidden;

    & .lightbox-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
    }

    & img {
      width: auto;
      height: 100%;

      margin: 0 auto;
      padding: 30px;

      @media (max-width: 768px) {
        padding: 20px;
      }
    }
  }
`;

const CloseLightbox = styled.button`
  position: absolute;
  top: 0;
  right: 0;

  color: #000;

  z-index: 100;

  line-height: 1;
  font-size: 20px;

  padding: 20px;

  cursor: pointer;
`;

export const Lightbox = ({
  data,
  isLightboxOpen,
  setLightBoxOpen,
  selectedIndex,
}) => {
  const [openLightbox, setOpenLightbox] = useState(false);

  useEffect(() => {
    if (isLightboxOpen === true) {
      const timer = setTimeout(() => {
        setOpenLightbox(true);
        document.body.style.overflow = "hidden";
      }, 10);
      return () => clearTimeout(timer);
    } else {
      setOpenLightbox(false);
      document.body.style.overflow = "";
    }
  }, [isLightboxOpen]);

  return (
    <LightboxContainer displayLightbox={openLightbox}>
      <Div100vh>
        <div className="lightbox-wrapper">
          <CloseLightbox onClick={() => setLightBoxOpen(false)}>
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M2 2L17 17" stroke="#000" strokeWidth="3" />
              <path d="M17 2L2 17" stroke="#000" strokeWidth="3" />
            </svg>
          </CloseLightbox>
          <Gallery
            data={data}
            objectFit={`contain`}
            selectedIndex={selectedIndex}
          />
        </div>
      </Div100vh>
    </LightboxContainer>
  );
};
