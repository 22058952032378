import React, { useContext } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { withPreview } from "gatsby-source-prismic";
import { Helmet } from "react-helmet";
import Media from "react-media";

// Context
import { PageColorConsumer } from "../components/context/page-color";
import { PageTypeConsumer } from "../components/context/page-type";
import WindowWidthContext from "../components/context/window-width";

// Components
import { ProjectDesktop } from "../components/project/project-desktop";
import { ProjectMobile } from "../components/project/project-mobile";

const Page = styled.div`
  & a {
    &:hover {
      font-family: "RotationLTStd-Italic";
    }
  }

  & .nhg-small {
    & h1,
    & h2,
    & h3,
    & h4,
    & p {
      font-family: "neue-haas-grotesk-text", sans-serif;
      font-weight: 700;
      font-style: normal;

      font-size: 12px;
      line-height: 15px;

      letter-spacing: 0.02em;
      text-transform: uppercase;
    }
  }
`;

const Project = ({ data, location, pageContext }) => {
  const windowWidth = useContext(WindowWidthContext);

  return (
    <PageTypeConsumer>
      {({ setPageType }) => (
        <PageColorConsumer>
          {({ setPageColor }) => (
            <Page>
              <Helmet
                title={`${data.prismicProject.data.title.text} – PUP ARCHITECTS`}
              />

              <Media
                queries={{ medium: "(max-width: 768px)" }}
                defaultMatches={{ medium: windowWidth === 768 }}
                render={() => (
                  <ProjectMobile
                    data={data}
                    setPageColor={setPageColor}
                    setPageType={setPageType}
                    location={location}
                    pageContext={pageContext}
                  />
                )}
              />

              <Media
                queries={{ medium: "(min-width: 769px)" }}
                defaultMatches={{ medium: windowWidth === 769 }}
                render={() => (
                  <ProjectDesktop
                    data={data}
                    setPageColor={setPageColor}
                    setPageType={setPageType}
                  />
                )}
              />
            </Page>
          )}
        </PageColorConsumer>
      )}
    </PageTypeConsumer>
  );
};

export const query = graphql`
  query Project($uid: String!) {
    prismicProject(uid: { eq: $uid }) {
      uid
      id
      data {
        title {
          html
          text
        }
        year
        text {
          html
        }
        subtitle {
          html
        }
        related_projects {
          project {
            document {
              ... on PrismicProject {
                id
                data {
                  title {
                    html
                  }
                  year(formatString: "YYYY")
                }
                uid
              }
            }
          }
        }
        color
        client {
          html
        }
        additional_credits {
          credit_text {
            html
          }
          credit_title {
            html
          }
        }
        body {
          ... on PrismicProjectBodyContentRowVideo {
            id
            slice_type
            primary {
              content_width
              content_alignment
              video {
                html
              }
              video_text {
                html
              }
            }
          }
          ... on PrismicProjectBodyImageRow {
            id
            slice_type
            items {
              images {
                fluid {
                  srcWebp
                  srcSetWebp
                }
                alt
              }
            }
            primary {
              image_text {
                html
              }
              content_width
              content_alignment
              show_in_lightbox
            }
          }
        }
        expandable_credits {
          credit_title {
            html
          }
          credit_text {
            html
          }
        }
        expandable_credits_title {
          text
        }
      }
    }
  }
`;

export default withPreview(Project);
