import React, { useEffect, useState } from "react";

// Styles
import "../styles/plyr.css";

export const VideoWithControls = ({ id, video }) => {
  const [player, setPlayer] = useState(null);

  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const Plyr = require("plyr");

      setPlayer(
        new Plyr(`#video-with-controls${id}`, {
          autoplay: false,
          muted: false,
          loop: { active: false },
          controls: ["current-time", "play", "mute", "progress", "fullscreen"],
          hideControls: false,
          // youtube: { modestbranding: 1, playsinline: 0, autoplay: 0 },
          clickToPlay: true,
        })
      );
    }
  }, []);

  return (
    <div
      className="plyr__video-embed"
      id={`video-with-controls${id}`}
      dangerouslySetInnerHTML={{
        __html: video,
      }}
    />
  );
};
