import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

// Utils
import { DeterminePageColor } from "../utils/determine-page-color";
import { DetermineLinkColor } from "../utils/determine-link-color";

// Components
import { Lightbox } from "../images/lightbox";
import { VideoWithControls } from "../video/video-with-controls";

const ProjectContainer = styled.div`
  display: block;
  width: 100%;

  padding: 0;
`;

const Title = styled.div`
  padding: 0 10px;
  margin: 40px 0;

  & h1 {
    text-align: center;
    text-transform: none;
  }
`;

const FlexWrapper = styled.div`
  width: 100%;

  padding: 0 10px;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.justifyContent};
`;

const FlexCol = styled.div`
  flex: ${props => props.flex};
  max-width: ${props => props.flex};

  margin: 0 0 60px 0;

  & .image-text {
    max-width: 450px;

    & p:last-of-type {
      margin-bottom: 0;
    }
  }

  @media (max-width: 767px) {
    flex: 100%;
    max-width: 100%;

    margin: 0 0 34px 0;
  }
`;

const ContentContainer = styled.div`
  display: ${props => props.display};

  & .text-container {
    padding: 0 10px;

    & strong {
      font-family: unset;
      font-size: unset;
      line-height: unset;
      letter-spacing: unset;
      text-transform: unset;
      font-weight: unset;
    }
  }

  & .projects-text,
  & .related-projects {
    margin: 12px 0;
  }

  & .projects-text {
    & .nhg-small {
      margin: 0 0 6px 0;
      line-height: 15px;
    }

    & .nhg-small {
      & p {
        margin-bottom: 0;
      }
    }

    & .body-text {
      & p {
        margin: 0;
      }
    }
  }

  & .related-projects {
    & .nhg-small {
      margin: 0 0 6px 0;
      line-height: 15px;
    }

    & h1 {
      font-family: "RotationLTStd-Roman";
      font-size: 15px;
      line-height: 20px;

      &:hover {
        font-family: "RotationLTStd-Italic";
      }
    }
  }
`;

const ProjectMenu = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  position: sticky;
  top: 20px;
  z-index: 10;

  background-color: ${props => props.bgColor};

  padding: 1px 10px 0 10px;

  width: 100%;

  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  letter-spacing: 0.02em;

  font-family: "neue-haas-grotesk-text", sans-serif;
  font-weight: 700;

  & button {
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    letter-spacing: 0.02em;

    cursor: pointer;

    &:hover {
      color: ${props => props.linkColor};
    }
  }

  & a.next {
    margin: 0 30px 0 13px;
  }

  & .links {
    & a:hover {
      font-family: "neue-haas-grotesk-text", sans-serif;
      color: ${props => props.linkColor};
    }
  }
`;

const Toggle = styled.button`
  display: ${props => props.display};
`;

const ImageContainer = styled.div`
  & img {
    cursor: auto;
  }
`;

const ExpandableCreditsContainer = styled.div`
  & button {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    width: 100%;
    cursor: pointer;
  }

  & .expandable-credits {
    display: ${props => props.display};
    margin: 1em 0;
  }
`;

export const ProjectMobile = ({
  data,
  setPageColor,
  setPageType,
  location,
  pageContext,
}) => {
  const [isTextOrImage, setTextOrImage] = useState("image");
  const [lightboxItems, setLightboxItems] = useState(null);
  const [isLightboxOpen, setLightBoxOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isExpandableCreditsOpen, setIsExpandableCreditsOpen] = useState(false);

  useEffect(() => {
    setPageColor(data.prismicProject.data.color);
    setPageType("project");

    document.body.style.overflow = "";
  }, [data]);

  const lightboxImages = data.prismicProject.data.body
    .filter(content => content.slice_type === "image_row")
    .filter(content => content.show_in_lightbox !== false)
    .map(content => {
      const items = content.items
        .filter(image => image.images !== undefined)
        .filter(
          image =>
            image.images.fluid !== null && image.images.fluid !== undefined
        );

      return (content = {
        items: items,
        id: content.id,
      });
    })
    .filter(content => content.items.length >= 1);

  useEffect(() => {
    if (lightboxImages.length >= 1) {
      setLightboxItems(lightboxImages);
      setSelectedIndex(lightboxImages[0].id);
    }
  }, []);

  const content = data.prismicProject.data.body.map((content, index) => {
    let contentWidth;
    let contentAlignment;
    if (content.primary.content_width === "Small") {
      contentWidth = `33.333%`;
    }
    if (content.primary.content_width === "Medium") {
      contentWidth = `50%`;
    }
    if (content.primary.content_width === "Large") {
      contentWidth = `100%`;
    }

    if (content.primary.content_alignment === "Left") {
      contentAlignment = `flex-start`;
    }
    if (content.primary.content_alignment === "Center") {
      contentAlignment = `center`;
    }
    if (content.primary.content_alignment === "Right") {
      contentAlignment = `flex-end`;
    }

    if (content.slice_type === "image_row") {
      const filteredImages = content.items
        .filter(image => image.images !== undefined)
        .filter(
          image =>
            image.images.fluid !== null && image.images.fluid !== undefined
        );

      const firstImage = filteredImages
        .filter((image, index) => index === 0)
        .map((image, index) => (
          <ImageContainer
            key={`first_image_${index}_${content.id}`}
            onClick={() => {
              if (content.show_in_lightbox !== false) {
                setLightBoxOpen(!isLightboxOpen);
                setSelectedIndex(content.id);
              } else {
                return null;
              }
            }}
          >
            <img
              srcSet={image.images.fluid.srcSetWebp}
              src={image.images.fluid.srcWebp}
              alt={image.images.alt}
              loading="lazy"
            />

            {/* {filteredImages.length > 1 && (
              <Caption className="nhg-small">1/{filteredImages.length}</Caption>
            )} */}
          </ImageContainer>
        ));

      return (
        <FlexWrapper
          key={`project_content_row_${index}_${data.prismicProject.id}`}
        >
          <Flex justifyContent={contentAlignment}>
            <FlexCol flex={contentWidth}>
              {firstImage.length >= 1 && firstImage}
              <div
                className="image-text"
                dangerouslySetInnerHTML={{
                  __html: content.primary.image_text.html,
                }}
              />
            </FlexCol>
          </Flex>
        </FlexWrapper>
      );
    }

    if (content.slice_type === "content_row_-_video") {
      return (
        <FlexWrapper
          key={`project_content_row_${index}_${data.prismicProject.id}`}
        >
          <Flex justifyContent={contentAlignment}>
            <FlexCol flex={contentWidth}>
              <VideoWithControls
                id={`project_content_row_${index}_${data.prismicProject.id}`}
                video={content.primary.video.html}
              />
              <div
                className="image-text"
                dangerouslySetInnerHTML={{
                  __html: content.primary.video_text.html,
                }}
              />
            </FlexCol>
          </Flex>
        </FlexWrapper>
      );
    }
  });

  const additionalCredits = data.prismicProject.data.additional_credits.map(
    (content, index) => (
      <div key={`additional_credits_${index}`}>
        <div
          className="nhg-small"
          dangerouslySetInnerHTML={{
            __html: content.credit_title.html,
          }}
        />
        <div
          className="body-text"
          dangerouslySetInnerHTML={{
            __html: content.credit_text.html,
          }}
        />
      </div>
    )
  );

  const expandableCredits = data.prismicProject.data.expandable_credits
    .filter(content => content.credit_title.html !== "")
    .map((content, index) => (
      <div
        key={`expandable_credits_${index}`}
        className="single-line-project-text"
      >
        <div
          className="nhg-small"
          dangerouslySetInnerHTML={{
            __html: content.credit_title.html,
          }}
        />
        <div
          className="body-text"
          dangerouslySetInnerHTML={{
            __html: content.credit_text.html,
          }}
        />
      </div>
    ));

  const relatedProjects = data.prismicProject.data.related_projects
    .filter(content => content.project.document !== null)
    .map((content, index) => (
      <div key={`related_projects_${index}`}>
        <Link to={`/projects/${content.project.document.uid}`}>
          <div
            dangerouslySetInnerHTML={{
              __html: content.project.document.data.title.html,
            }}
          />
        </Link>
      </div>
    ));

  return (
    <>
      <ProjectContainer>
        <ProjectMenu
          bgColor={DeterminePageColor(data.prismicProject.data.color)}
          linkColor={DetermineLinkColor(data.prismicProject.data.color)}
        >
          <Toggle
            onClick={() => setTextOrImage("text")}
            display={isTextOrImage === "image" ? `block` : `none`}
          >
            Information
          </Toggle>
          <Toggle
            onClick={() => setTextOrImage("image")}
            display={isTextOrImage === "text" ? `block` : `none`}
          >
            Imagery
          </Toggle>

          <div className="links">
            <Link to={`/projects/${pageContext.prev.uid}`} className="previous">
              Previous
            </Link>
            <Link to={`/projects/${pageContext.next.uid}`} className="next">
              Next
            </Link>
          </div>
        </ProjectMenu>

        <Title
          className="nhg-title"
          dangerouslySetInnerHTML={{
            __html: data.prismicProject.data.title.html,
          }}
        />

        <ContentContainer display={isTextOrImage === "text" ? `block` : `none`}>
          <div
            className="text-container"
            dangerouslySetInnerHTML={{
              __html: data.prismicProject.data.text.html,
            }}
          />

          <hr />

          <div className="projects-text text-container">
            <div>
              <h2 className="nhg-small">Client</h2>

              <div
                className="body-text"
                dangerouslySetInnerHTML={{
                  __html: data.prismicProject.data.client.html,
                }}
              />
            </div>

            {additionalCredits}
          </div>

          <hr />

          <div className="projects-text text-container">
            {expandableCredits.length >= 1 && (
              <ExpandableCreditsContainer
                display={isExpandableCreditsOpen === true ? `block` : `none`}
              >
                <button
                  className="nhg-small"
                  onClick={() =>
                    setIsExpandableCreditsOpen(!isExpandableCreditsOpen)
                  }
                >
                  {data.prismicProject.data.expandable_credits_title.text}

                  <span>{isExpandableCreditsOpen ? `–` : `+`}</span>
                </button>

                <div className="expandable-credits">{expandableCredits}</div>
              </ExpandableCreditsContainer>
            )}
          </div>

          {relatedProjects.length >= 1 && (
            <>
              <hr />
              <div className="related-projects text-container">
                <div className="nhg-small">
                  <h2>Related</h2>
                </div>
                {relatedProjects}
              </div>
            </>
          )}
        </ContentContainer>

        <ContentContainer
          display={isTextOrImage === "image" ? `block` : `none`}
        >
          {content}
        </ContentContainer>
      </ProjectContainer>

      {lightboxItems !== null && (
        <Lightbox
          data={lightboxItems}
          isLightboxOpen={isLightboxOpen}
          setLightBoxOpen={setLightBoxOpen}
        />
      )}
    </>
  );
};
